import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <p className="made-with-love">
        Made with <span className="hearts">&hearts;</span> in India
      </p>
      <p className="copyright">© {new Date().getFullYear()} @jaydp17</p>
    </footer>
  );
}
