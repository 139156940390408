import PropTypes from 'prop-types';
import React from 'react';
import '../css/001-bootstrap-things.css';
import '../css/010-color-blue.css';
import '../css/020-font.css';
import '../css/030-style.css';
import '../css/040-media-queries.css';
import Footer from './Footer';

interface Props {
  children: any;
}
const Layout = ({ children }: Props) => {
  return (
    <div className="full-page-height-root">
      <div className="height-stretch-container">
        <div id="wraper">
          <main>{children}</main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
